// Vue App dependencies
import Vue from 'vue'
import App from '@/App.vue'
import { InlineSvgPlugin } from 'vue-inline-svg'
import { newrelic } from '@sword-health/ui-core'
import $http from '@/plugins/http-requests'
import FloatingVue, { floatingVueOptions } from '@/plugins/floating-vue'
import dayjs from '@/plugins/dayjs'
import '@/plugins/notify'
import VueClipboard from 'vue-clipboard2'
import router from '@/router'
import store from '@/store'
import i18n from '@/scripts/app-configs/i18n-config'
import VueZendesk from '@/scripts/integrations/zendesk'
import directives from '@/directives'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { Vuelidate, vuelidateErrorExtractor, vuelidateErrorExtractorOpts, InputErrorWuk } from '@/scripts/app-configs/vuelidate-config'
import SwordIconWuk from '@ui-kit/components/icons/SwordIcon.vue'

import '@/styles/tailwind.scss'
import '@/styles/main.scss'

newrelic.listen()

Vue.use(directives)
Vue.use($http)
Vue.use(VueZendesk)
Vue.use(dayjs)
Vue.use(InlineSvgPlugin)
Vue.use(VueClipboard)
Vue.use(VueQueryPlugin)
Vue.use(FloatingVue, floatingVueOptions)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Vue.use(Vuelidate as any)
Vue.use(vuelidateErrorExtractor, vuelidateErrorExtractorOpts)
Vue.component('InputErrorWuk', InputErrorWuk)
Vue.component('SwordIcon', SwordIconWuk)

Vue.config.errorHandler = function errorHandlerFn(err) {
  if (process.env.NODE_ENV !== 'development') {
    window.newrelic?.noticeError(err)
  }
  console.error(err)
}

export default {
  router,
  i18n,
  store,
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
