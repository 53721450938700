/* eslint-disable */
const convertDateToClientTZ = (date) => {
  const instanceTS = new Date(date).getTime();
  const unixTSDiff = (new Date(date).getTimezoneOffset() * -1) * 60000;
  return instanceTS + unixTSDiff;
};

function isValidJSONstring(str) {
  try {
    const json = JSON.parse(str)

    return typeof json === 'object' && json
  } catch (e) {
    return false
  }
}

function getFormattedTimeAnnotation(date) {
  let hours = date.get('hour')
  let minutes = date.get('minute')
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours = hours % 12 || 12
  minutes = minutes.toString().padStart(2, '0')
  return `${hours}:${minutes} ${ampm}`
}

export {
  convertDateToClientTZ,
  isValidJSONstring,
  getFormattedTimeAnnotation,
}
