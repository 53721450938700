import store from '@/store'
import { CONFIG } from '@/scripts/app-configs/constants'

import general from '@/http/endpoints/generalEndpoints'
import user from '@/http/endpoints/userEndpoints'
import patient from '@/http/endpoints/patientEndpoints'
import prescription from '@/http/endpoints/prescriptionEndpoints'
import patientsList from '@/http/endpoints/patientsListEndpoints'
import institutions from '@/http/endpoints/institutionsEndpoints'
import notifications from '@/http/endpoints/notificationsEndpoints'
import recommendations from '@/http/endpoints/recommendationsEndpoints'
import results from '@/http/endpoints/resultsEndpoints'
import config from '@/http/endpoints/configEndpoints'
import stepCountGoal from '@/http/endpoints/stepCountGoal'
import patientsFunnel from '@/http/endpoints/patientsFunnelEndpoints'
import activityReports from '@/http/endpoints/activityReportsEndpoints'
import aiFeed from '@/http/endpoints/aiFeed'
import swordDesk from '@/http/endpoints/swordDesk'

// Move
import prescriptionMove from '@/http/endpoints/move/prescriptionEndpoints'
import membersMove from '@/http/endpoints/move/membersEndpoints'

//Bloom
import prescriptionBloom from '@/http/endpoints/bloom/prescriptionEndpoints'

// Mind
import prescriptionMind from '@/http/endpoints/mind/prescriptionEndpoints'
import programGoals from '@/http/endpoints/mind/programGoalsEndpoints'

export function generateAuthTokens() {
  return {
    authorization: { 'Authorization': store.getters['user/authentication/accessToken'] },
    refreshAuthorization: { 'Authorization': store.getters['user/authentication/refreshToken'] },
  }
}

export const endpointsSpec = {
  data: {
    // TODO Is this being used ?!
    apiUrls: {
      localized: CONFIG.baseUrl,
    },
    headers: generateAuthTokens(),
  },
  modules: {
    user,
    general,
    patient,
    prescription,
    patientsList,
    institutions,
    notifications,
    recommendations,
    results,
    config,
    patientsFunnel,
    stepCountGoal,
    activityReports,
    prescriptionMove,
    prescriptionBloom,
    prescriptionMind,
    programGoals,
    membersMove,
    aiFeed,
    swordDesk,
  },
}
