<template>
  <feed-item
    :is-new="!notification.is_read"
    :vip="notification.metadata.patient_is_vip_account"
    :gender="notification.metadata.gender"
    :picture="notification.metadata.picture"
    :notification-type="notification?.type"
    @picture-click="openInNewTab('profile.board', { patientID: notification.metadata.patient_id })"
    @item-click="handleItemClick(notification.type, notification.metadata.patient_id)"
  >
    <div class="notification__content">
      <div class="notification__text">
        <p v-if="notification.type === 'new_voicemail'">
          [{{ $t(notificationVoicemailTranslation.copyErrorType) }}]:
          {{ $t(notificationVoicemailTranslation.copyMessage, notificationVoicemailTranslation.copyMessageParams) }}
        </p>
        <p v-else>{{ $t(notificationTranslation.copy, notificationTranslation.copyParams) }}</p>
        <time class="t3 fc-ctx-5" :datetime="notification.updated_at">{{ formattedNotificationDate }}</time>
      </div>
    </div>
  </feed-item>
</template>

<script>
import FeedItem from '@/components/feed-item.vue'

// Configs
import { NOTIFICATION_TYPES } from '@/scripts/configs/notifications'

// Composables
import useOpenNewTab from '@/composables/use-open-new-tab'

// utils
import { openWebChat } from '@/scripts/utils/feature-toggle-routing'
import { getFormattedTimeAnnotation } from '@/scripts/utils'

const TRANSLATIONS = {
  [NOTIFICATION_TYPES.NEW_CHAT_MESSAGE]: 'NOTIFICATIONS.NOTIFICATIONS.MEMBER_SENT_MESSAGE',
  INVALID_OR_MISSING_RECORD: 'voicemail.notification.fail_reason.missing_or_invalid_voice_record',
  DEFAULT_MESSAGE: 'voicemail.notification.message.default',
  ERROR_MESSAGE: 'voicemail.notification.message.error',
}

export default {
  name: 'NotificationItem',
  components: {
    FeedItem,
  },
  props: {
    notification: {
      type: Object,
    },
  },
  setup() {
    const openInNewTab = useOpenNewTab()

    return {
      openInNewTab,
    }
  },
  computed: {
    notificationTranslation() {
      return {
        copy: TRANSLATIONS[this.notification.type] || '',
        copyParams: {
          member: `${this.notification.metadata.first_name} ${this.notification.metadata.last_name}`,
        },
      }
    },
    formattedNotificationDate() {
      const annotation = getFormattedTimeAnnotation(this.$date(this.notification.updated_at))

      return `${this.$date().defaultDiff(this.notification.updated_at)}, ${annotation} `
    },

    patientName() {
      const firstName = this.notification.metadata?.first_name
      const lastName = this.notification.metadata?.last_name || ''

      return firstName ? `${firstName} ${lastName}` : 'Unknown'
    },

    isValidRecord() {
      const record = this.notification?.metadata?.record

      return !!(record && typeof record === 'string' && record.trim())
    },

    notificationVoicemailTranslation() {
      const errorType = this.notification?.metadata?.fail_reason || 'Unknown'
      let message = TRANSLATIONS.ERROR_MESSAGE

      if (errorType === TRANSLATIONS.INVALID_OR_MISSING_RECORD && !this.isValidRecord) {
        message = TRANSLATIONS.DEFAULT_MESSAGE
      }

      return {
        copyErrorType: errorType,
        copyMessage: message,
        copyMessageParams: {
          member: this.patientName,
          record_audio: this.notification?.metadata?.record_audio || 'Unknown',
        },
      }
    },
  },

  methods: {
    openChat() {
      openWebChat({ channelId: this.notification.metadata.channel_url })
      this.$emit('close')
    },
    handleItemClick(notificationType) {
      const clickHandlers = {
        [NOTIFICATION_TYPES.NEW_CHAT_MESSAGE]: this.openChat(),
        [NOTIFICATION_TYPES.NEW_VOICEMAIL]: this.openChat(),
      }

      return clickHandlers[notificationType]
    },
  },
}
</script>

<style lang="scss" scoped>
.notification {
  &__content {
    display: flex;
    width: 100%;
  }

  &__text {
    flex: 1;
    line-height: 1.375rem;
  }
}

.options-menu-wrapper {
  ::v-deep {
    .options-list-item {
      justify-content: left !important;
    }

    & .options-menu-button__icon {
      width: 1rem;
      height: 1rem;
      margin: auto;
    }
  }
}
</style>
