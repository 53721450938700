import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { tools } from '@sword-health/ui-core'
import { CONFIG, FEATURE_TOGGLE, VERSION_FILE, EXTERNAL_LINKS } from '@/scripts/app-configs/constants'
import i18n from '@/scripts/app-configs/i18n-config'

// Utils
import { triggers } from '@/scripts/global-modals-commands'

Vue.use(VueRouter)

const VERSION_CHECK_DEBOUNCE = 10 * 1000 // set a 10 second debounce between version checks

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      return { name: store.getters['system/defaultPage'].state }
    },
  }, {
    path: '/tasks',
    component: () => import(/* webpackChunkName: "appMain" */ '@/views/masters/app-main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "umlLayout" */ '@/views/ai-feed/layouts/ai-feed-default.vue'),
        name: 'ai-feed',
        children: [
          {
            path: ':masterTaskId?',
            name: 'ai-feed-master-task',
            component: () => import(/* webpackChunkName: "umlMasterTaskView" */ '@/views/ai-feed/pages/master-task.vue'),
            props: true,
          },
        ],
      },
    ],
  }, {
    path: '/logout',
    name: 'logout',
    redirect: async () => {
      return { name: 'login' }
    },
  }, {
    path: '/login',
    component: () => import(/* webpackChunkName: "authenticationView" */ '@/views/masters/authentification-view.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/authentification/login.vue'),
        meta: {
          unauthorizedAccess: true,
          checkAppVersion: true,
        },
      },
    ],
  }, {
    path: '/forgot',
    component: () => import(/* webpackChunkName: "authenticationView" */ '@/views/masters/authentification-view.vue'),
    children: [
      {
        path: '',
        name: 'forgot',
        component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/authentification/forgot-password.vue'),
        meta: { unauthorizedAccess: true },
      },
    ],
  }, {
    path: '/reset/:token',
    component: () => import(/* webpackChunkName: "authenticationView" */ '@/views/masters/authentification-view.vue'),
    children: [
      {
        path: '',
        name: 'reset',
        component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/authentification/reset-password.vue'),
        meta: { unauthorizedAccess: true },
      },
    ],
  }, {
    path: '/unlock/:token',
    component: () => import(/* webpackChunkName: "authenticationView" */ '@/views/masters/authentification-view.vue'),
    children: [
      {
        path: '',
        name: 'unlock',
        component: () => import(/* webpackChunkName: "unlockAccount" */ '@/views/authentification/unlock-account.vue'),
        meta: { unauthorizedAccess: true },
      },
    ],
  }, {
    path: '/chat',
    beforeEnter: (to, from, next) => {
      document.title = `Chat | ${process.env.NODE_ENV === 'development' ? 'DEV - SWORD Health' : 'SWORD Health'}`

      return next()
    },
    component: () => import(/* webpackChunkName: "appMain" */ '@/views/masters/app-main.vue'),
    meta: {
      isFeatureEnabled: FEATURE_TOGGLE.USE_UI_PORTAL_CHAT,
    },
    children: [
      {
        path: '/chat/:pathMatch(.*)*',
        name: 'chat',
        component: () => import(/* webpackChunkName: "chat" */ '@/views/chat/pages/chat.vue'),
      },
    ],
  }, {
    path: '/patients-funnel',
    component: () => import(/* webpackChunkName: "appMain" */ '@/views/masters/app-main.vue'),
    children: [
      {
        path: ':activeFunnel?',
        name: 'patients-funnel',
        component: () => import(/* webpackChunkName: "membersFunnel" */ '@/views/members-funnel'),
      },
    ],
  }, {
    path: '/patient',
    component: () => import(/* webpackChunkName: "clearRoot" */ '@/views/masters/clear-root.vue'),
    children: [
      {
        path: 'add',
        name: 'patient.add',
        component: () => import(/* webpackChunkName: "login" */ '@/views/patient/profile-forms/patient-profile-form.vue'),
      }, {
        path: ':patientID/edit/:section?',
        name: 'patient.edit',
        component: () => import(/* webpackChunkName: "patientProfileForm" */ '@/views/patient/profile-forms/patient-profile-form.vue'),
      }, {
        path: ':patientID/card',
        name: 'patient.card',
        component: () => import(/* webpackChunkName: "patientCardForm" */ '@/views/patient/profile-forms/patient-card-form.vue'),
      },
    ],
  }, {
    path: '/patients/:patientID',
    component: () => import(/* webpackChunkName: "appMain" */ '@/views/masters/app-main.vue'),
    children: [
      {
        path: 'board',
        name: 'profile.board',
        component: () => import(/* webpackChunkName: "patientScreen" */ '@/views/patient/patient-screen.vue'),
        meta: {
          uiPortalRedirect: {
            enabled: false,
            path: ({ memberUuid }) => `/app/members/${memberUuid}/board`,
          },
        },
      }, {
        path: 'profile',
        name: 'profile.details',
        component: () => import(/* webpackChunkName: "patientDetails" */ '@/views/patient/patient-details.vue'),
      }, {
        path: 'change-state',
        name: 'profile.changeState',
        component: () => import(/* webpackChunkName: "changeState" */ '@/views/patient/change-state.vue'),
      }, {
        path: 'assign-kit',
        name: 'profile.assign-kit',
        component: () => import(/* webpackChunkName: "assignKit" */ '@/views/patient/assign-kit.vue'),
      }, {
        path: 'clinical-history',
        name: 'profile.clinical-history',
        component: () => import(/* webpackChunkName: "patientClinicalHistoryCDA" */ '@/views/patient/patient-clinical-history-CDA.vue'),
      }, {
        path: 'treatment-path',
        name: 'profile.treatmentPath',
        component: () => import(/* webpackChunkName: "treatmentPath" */ '@/views/patient/treatment-path.vue'),
      }, {
        path: 'sessions',
        name: 'profile.sessions',
        component: () => import(/* webpackChunkName: "patientPrescribedSessions" */ '@/views/patient/patient-prescribed-sessions.vue'),
      }, {
        // TODO: Deprecate the Move routes below. Remove after 2025-06-01
        path: 'move/prescriptions',
        name: 'profile.move-prescriptions',
        redirect: ({ params }) => {
          return { name: 'prescriptions.move', params: { memberId: params.patientID } }
        },
      }, {
        path: 'move/prescriptions/add',
        name: 'profile.move-prescriptions-add',
        redirect: ({ params }) => {
          return { name: 'prescriptions.move.add', params: { memberId: params.patientID, prescriptionID: params.prescriptionID } }
        },
      }, {
        path: 'move/prescriptions/:prescriptionID/edit',
        name: 'profile.move-prescriptions-edit',
        redirect: ({ params }) => {
          return { name: 'prescriptions.move.edit', params: { memberId: params.patientID, prescriptionID: params.prescriptionID } }
        },
      }, {
        path: 'move/prescriptions/:prescriptionID/overview',
        name: 'profile.move-prescriptions-overview',
        redirect: ({ params }) => {
          return { name: 'prescriptions.move.overview', params: { memberId: params.patientID, prescriptionID: params.prescriptionID } }
        },
      },
    ],
  }, {
    path: '/prescription/:patientID/session/:sessionID?',
    name: 'prescription',
    component: () => import(/* webpackChunkName: "prescription" */ '@/views/prescription/prescription.vue'),
    children: [
      {
        path: 'prescription-clinical-history-check',
        name: 'prescription.clinicalHistoryCheck',
      }, {
        path: 'messages',
        name: 'prescription.messages',
      }, {
        path: 'prescription-type',
        name: 'prescription.type',
      }, {
        path: 'therapies',
        name: 'prescription.therapies',
      }, {
        path: 'therapy/options',
        name: 'prescription.therapiesOption',
      }, {
        path: 'exercises',
        name: 'prescription.exercises',
      }, {
        path: 'manual-planning',
        name: 'prescription.manual-planning',
      }, {
        path: 'changes',
        name: 'prescription.justify',
      }, {
        path: '/prescription/:patientID/recommendations',
        name: 'prescription.recommendations',
      }, {
        path: '/prescription/:patientID/recommendations/edit',
        name: 'prescription.recommendations.edit',
      }, {
        path: 'exercise-levels/:exerciseID',
        name: 'prescription.exerciselevels',
      }, {
        path: 'summary',
        name: 'prescription.summary',
      }, {
        path: 'summary',
        name: 'prescription.success',
      },
    ],
  }, {
    path: '/prescriptions',
    component: () => import(/* webpackChunkName: "appMain" */ '@/views/masters/app-main.vue'),
    children: [
      {
        path: ':memberId',
        component: () => import(/* webpackChunkName: "prescriptionsLayout" */ '@/views/prescriptions/layouts/prescriptions-default.vue'),
        name: 'prescriptions',
        children: [
          {
            path: 'bloom',
            name: 'prescriptions.bloom',
            component: () => import(/* webpackChunkName: "prescriptionsBloom" */ '@/views/prescriptions/pages/[memberId]/bloom/index.vue'),
          },
          {
            path: 'sword',
            name: 'prescriptions.sword',
            component: () => import(/* webpackChunkName: "prescriptionsSword" */ '@/views/prescriptions/pages/[memberId]/sword/index.vue'),
          },
          {
            path: 'move',
            name: 'prescriptions.move',
            component: () => import(/* webpackChunkName: "prescriptionsMove" */ '@/views/prescriptions/pages/[memberId]/move/index.vue'),
          },
          {
            path: 'move/:prescriptionID/overview',
            name: 'prescriptions.move.overview',
            component: () =>
              import(/* webpackChunkName: "prescriptionsMoveOverview" */ '@/views/prescriptions/pages/[memberId]/move/[prescriptionID]/overview.vue'),
          },
          {
            path: 'move/:prescriptionID/edit',
            name: 'prescriptions.move.edit',
            component: () =>
              import(/* webpackChunkName: "prescriptionsMoveEdit" */ '@/views/prescriptions/pages/[memberId]/move/[prescriptionID]/edit.vue'),
          },
          {
            path: 'move/:prescriptionID/add',
            name: 'prescriptions.move.add',
            component: () =>
              import(/* webpackChunkName: "prescriptionsMoveAdd" */ '@/views/prescriptions/pages/[memberId]/move/[prescriptionID]/add.vue'),
          },
          {
            path: 'mind',
            name: 'prescriptions.mind',
            component: () => import(/* webpackChunkName: "prescriptionsMind" */ '@/views/prescriptions/pages/[memberId]/mind/index.vue'),
          },
          {
            path: 'mind/add',
            name: 'prescriptions.mind.add',
            component: () => import(/* webpackChunkName: "prescriptionsMindAdd" */ '@/views/prescriptions/pages/[memberId]/mind/add.vue'),
          },
          {
            path: 'mind/change-goal',
            name: 'prescriptions.mind.change-goal',
            component: () =>
              import(/* webpackChunkName: "prescriptionsMindChangeGoal" */ '@/views/prescriptions/pages/[memberId]/mind/change-goal.vue'),
          },
          {
            path: 'mind/:prescriptionID',
            name: 'prescriptions.mind.week',
            component: () => import(/* webpackChunkName: "prescriptionsMindEdit" */ '@/views/prescriptions/pages/[memberId]/mind/week.vue'),
          },
        ],
      },
    ],
  }, {
    path: '/therapy_results/:patientID',
    component: () => import(/* webpackChunkName: "appMain" */ '@/views/masters/app-main.vue'),
    children: [
      {
        path: 'sessions',
        name: 'therapy_results.sessions',
        component: () => import(/* webpackChunkName: "patientResults" */ '@/views/results/pages/patient-results.vue'),
      }, {
        path: 'clinical-sessions',
        name: 'therapy_results.clinical-sessions',
        component: () => import(/* webpackChunkName: "clinicalPatientResults" */ '@/views/results/pages/clinical-patient-results.vue'),
      }, {
        path: 'exercises/:sessionID/group/:sessionGroup',
        name: 'therapy_results.exercises',
        component: () => import(/* webpackChunkName: "sessionResults" */ '@/views/results/pages/session-results.vue'),
      },
    ],
  }, {
    path: '/assessments/:patientID',
    component: () => import(/* webpackChunkName: "clearRoot" */ '@/views/masters/clear-root.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "clinicalForms" */ '@/views/clinical-records/clinical-forms.vue'),
        children: [
          {
            path: 'new-form',
            name: 'clinical-records.newForm',
            component: () => import(/* webpackChunkName: "clinicalFormsCreation" */ '@/views/clinical-records/pages/create-form-filters.vue'),
          }, {
            path: 'form-answers/:formID',
            name: 'clinical-records.formAnswers',
            component: () => import(/* webpackChunkName: "clinicalFormsAnswers" */ '@/views/clinical-records/pages/clinical-form-answers.vue'),
          },
        ],
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "appMain" */ '@/views/masters/app-main.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "clinicalForms" */ '@/views/clinical-records/clinical-forms.vue'),
            children: [
              {
                path: 'listing',
                name: 'clinical-records.listing',
                component: () => import(/* webpackChunkName: "clinicalFormsListing" */ '@/views/clinical-records/pages/clinical-forms-listings.vue'),
              },
              {
                path: 'fill',
                name: 'clinical-records.fill',
                component: () => import(/* webpackChunkName: "clinicalFormsFill" */ '@/views/clinical-records/pages/clinical-form-fill.vue'),
              },
            ],
          },
        ],
      },
    ],
  }, {
    path: '/profile',
    name: 'professional-profile',
    component: () => import(/* webpackChunkName: "personalInformation" */ '@/views/professional/personal-information.vue'),
  }, {
    path: '/remote-config',
    name: 'remote-config',
    component: () => import(/* webpackChunkName: "professionalOnboarding" */ '@/views/professional/professional-onboarding.vue'),
  }, {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "changePassword" */ '@/views/professional/change-password.vue'),
  }, {
    path: '/preferences',
    name: 'preferences',
    component: () => import(/* webpackChunkName: "preferences" */ '@/views/professional/preferences.vue'),
  }, {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "termsAndConditions" */ '@/views/professional/terms-and-conditions.vue'),
  }, {
    path: '/version',
    name: 'version',
    component: () => import(/* webpackChunkName: "appVersion" */ '@/views/app-version.vue'),
  }, {
    path: '/unauthorized',
    name: 'unauthorized-view',
    component: () => import(/* webpackChunkName: "unauthorizedView" */ '@/views/unauthorized-view.vue'),
  }, {
    path: '*',
    component: () => import(/* webpackChunkName: "pageNotFound" */ '@/views/page-not-found.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

const checkVersion = async (stateName, stateMetadata = {}) => {
  // check version of client;
  if (!stateMetadata.checkAppVersion) {
    return
  }
  await store.dispatch('system/importPortalVersion', VERSION_FILE)
  const appUpdated = store.getters['system/appUpdated']

  if (!appUpdated) {
    window.location.reload()
    console.warn('updated portal version with refresh')
  }
}

const ACLCheckOk = (stateMetadata = {}) => {
  const featureDisabled = stateMetadata.isFeatureEnabled === false
  const userAuthenticated = store.getters['user/authentication/isAuthenticated']
  const userCanAccess = userAuthenticated || stateMetadata.unauthorizedAccess === true

  return !featureDisabled && userCanAccess
}

router.beforeEach(async (to, from, next) => {
  document.title = process.env.NODE_ENV === 'development' ? 'DEV - SWORD Health' : 'SWORD Health'

  const userAuthenticated = store.getters['user/authentication/isAuthenticated'] || (() => {
    store.commit('user/authentication/loadStoredAuthTokens')

    return store.getters['user/authentication/isAuthenticated']
  })()

  if ((to.name === 'logout') && userAuthenticated) {
    await store.dispatch('user/authentication/logout')
  }

  if ((to.name === 'login') && userAuthenticated) {
    router.push({ name: 'home' })

    return
  }

  if (to.query.origin === 'ui-portal' && 'patientID' in to.params) {

    const storePatient = store.getters['patient/getMemberBasics']
    let patientID = storePatient.id

    if (!storePatient.uuid || storePatient.uuid !== to.params.patientID) {
      try {
        store.commit('system/setUIReadyState', false)
        store.commit('system/setGlobalLoadingMessage', '')
        const { data } = await Vue.$http('patient/profile/getPatient', { patientID: to.params.patientID })

        patientID = data.user_id
      } catch (e) {
        Vue.noty.error(i18n.t('copy_89'))
        store.commit('system/resetGlobalLoadingMessage')
        router.replace({ name: 'home' })

        return
      } finally {
        store.commit('system/setUIReadyState', true)
      }
    }

    router.replace({ name: to.name, params: { ...to.params, patientID } })

    return
  }

  // Redirect to UI-Portal Portal if necessary
  if (FEATURE_TOGGLE.uiPortalRedirect && to.meta?.uiPortalRedirect?.enabled && to.meta?.uiPortalRedirect?.path) {
    let fullParams = to.params

    if (to.params.patientID) {
      try {
        store.commit('system/setUIReadyState', false)
        store.commit('system/setGlobalLoadingMessage', '')
        const { patientID: patientId } = to.params

        await store.dispatch('patient/fetchPatientProfile', patientId)
        const memberUuid = store.getters['patient/getMemberBasics'].uuid

        fullParams = { ...fullParams, memberUuid }

      } catch (e) {
        Vue.noty.error(i18n.t('copy_89'))
        store.commit('system/resetGlobalLoadingMessage')

        return
      } finally {
        store.commit('system/setUIReadyState', true)
      }
    }

    window.location.href = `${EXTERNAL_LINKS.uiPortal.url}${to.meta.uiPortalRedirect.path(fullParams)}`

    return
  }

  // Protecting against external malformed double slashed urls
  const externalSheetsProtectionRegex = new RegExp(/^(\/{2}(([a-zA-Z]).*))/)

  if (externalSheetsProtectionRegex.test(to.path)) {
    const safeRoutePath = to.path.replace(externalSheetsProtectionRegex, (a, b, c) => `/${c}`)

    router.replace({ path: safeRoutePath })

    return
  }

  // Protecting against old external prescriptions urls
  const externalSheetsProtectionPrescriptionRegex = new RegExp(/^(\/prescription\/([0-9]+)\/(?!session\/|recommendations))/)

  if (externalSheetsProtectionPrescriptionRegex.test(to.path)) {
    const patientID = to.path.match(externalSheetsProtectionPrescriptionRegex)[2]
    const { sessionID } = to.query

    if (!sessionID) {
      router.replace({ name: 'profile.sessions', params: { patientID } })

      return
    }
    router.replace({ name: 'prescription.exercises', params: { patientID, sessionID } })

    return
  }

  tools.debounce(checkVersion.bind(this, to.name, to.meta), VERSION_CHECK_DEBOUNCE, true)()
  tools.debounce(() => store.dispatch('user/authentication/checkTokenValidity'), VERSION_CHECK_DEBOUNCE, true)()

  if (to.meta?.unauthorizedAccess === true) {
    store.commit('system/setUIReadyState', true)
  }

  if (!ACLCheckOk(to.meta)) {
    const shouldRedirectAfterLogin = !userAuthenticated && to.matched.length && to.matched.some((route) => route.path !== '*')

    router.push({ name: 'login', params: { redirectPath: shouldRedirectAfterLogin ? to.fullPath : null } }).catch(() => {})

    return
  }

  next()
})

router.afterEach((to, from, failure) => {
  if (!failure && CONFIG.enableAnalytics) {
    gtag('config', CONFIG.analyticsKey, { page_path: router.currentRoute.fullPath })
  }

  try {
    triggers.closeChatPopup()
  } catch (error) {
    console.error(error)
  }
});

(function overwriteReplace() {
  if (router.back) {
    const old = router.back

    router.back = function overwriteBackFn(fallbackRoute, replace) {
      if (window.history.length <= 1 && fallbackRoute) {
        /* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
        replace ? router.replace(fallbackRoute) : router.push(fallbackRoute)

        return
      }
      old.call(this)
    }
  }
})()

export default router
