<template>
  <transition v-if="isModalOpen" name="patient-summary-bar">
    <div
      ref="patient-sidebar-modal-backdrop"
      class="modal-backdrop patient-summary-bar-backdrop"
      @click="handleCloseFromPropagation"
    >
      <div
        ref="patient-sidebar-wrapper"
        :class="['patient-summary-bar', { 'patient-summary-bar--loading': isLoading || !patientInfo }]"
      >
        <pulse-loader v-if="isLoading || !patientInfo" />
        <patient-summary-overview
          v-else
          :eligibility="patientInfo.insurance_info.eligibility"
          :care-coordinators="patientInfo.care_coordinators"
          :bloom-protocol="patientInfo.protocol"
          :profile-info="patientInfo.profile"
          :clinical-info="patientInfo.clinical"
          :reminders="patientInfo.reminders"
          :goals="patientInfo.goals"
          :therapist-id="therapistId"
          :protocol="protocolInfo"
          :is-blossom-member="isBlossomMember(patientInfo.profile.unit, patientInfo.profile.business_unit)"
          :app-version="patientInfo.app?.version"
          :age="age"
          :current-state="patientInfo && patientInfo.profile.current_state"
          :kit-history="patientInfo.kit_history"
          :contacts="contacts"
          attention-level-editable
          @close-side-bar="closeModal"
          @open-ui="openUi"
          @change-attention-level="changeAttentionLevel"
          @copy-to-clipboard="copyToClipboard"
          @send-to-user="sendToUser"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import Vue, { computed, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'

import { isBlossomMember } from '@/store/modules/patientStore'
// Composable
import useAppointmentLinks from '@/composables/use-appointment-links'

// Components
import PatientSummaryOverview from '@/components/modals/ProfileSummaryOverview'
import pulseLoader from '@/components/feedbacks/loaders/pulseLoader.vue'

// Utils
import { getProtocolInfo } from '@/scripts/configs/patient-protocol'
import { triggers } from '@/scripts/global-modals-commands'
import i18n from '@/scripts/app-configs/i18n-config'
import { shDate } from '@/utils/filters'

export default {
  name: 'PatientSummarySidebar',
  components: {
    pulseLoader,
    PatientSummaryOverview,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const isModalOpen = computed(() => props.modalOpen)
    const memberUuid = computed(() => props.payload?.memberUuid)
    const { generateAppointmentLink } = useAppointmentLinks(memberUuid)

    const {
      data: patientInfo,
      isLoading,
      error,
    } = useQuery({
      queryKey: ['summary-sidebar', memberUuid],
      queryFn: () => Vue.$http('patient/fetchPatientSummarySidebarInfo', memberUuid.value).then((res) => res.data),
      enabled: () => !!memberUuid.value,
      retry: 0,
    })

    watch(error, (isError) => {
      if (!isError) return
      triggers.toggleMemberSidebar()
      Vue.$notify.error(i18n.t('copy_89'))
    })

    return {
      // states
      memberUuid,
      patientInfo,
      isModalOpen,
      isLoading,
      // methods
      isBlossomMember,
      generateAppointmentLink,
      toggleNotes: triggers.toggleNotes,
      toggleMemberSidebar: triggers.toggleMemberSidebar,
    }
  },
  computed: {
    therapistId() {
      return this.$store.getters['user/getBasicProfile'].id
    },
    age() {
      const birthdate = this.patientInfo?.profile.birthdate

      return birthdate && shDate(birthdate, 'age', { input: 'YYYY-MM-DD' })
    },
    protocolInfo() {
      if (!this.patientInfo.protocol) {
        return undefined
      }

      return getProtocolInfo(this.patientInfo.protocol)
    },
    contacts() {
      if (!this.patientInfo) {
        return []
      }

      const phoneNumber = this.patientInfo.profile.phone
        ? `${this.patientInfo.profile.phone.prefix} ${this.patientInfo.profile.phone?.number}`
        : ''

      const phoneWarningMessage = this.patientInfo.profile.phone?.can_receive_sms === false
        ? this.$t('webchat_sidebar_profile.number_not_eligible_sms')
        : undefined

      return [
        { label: 'PROFILE.PERSONAL_INFORMATION.PREFERRED_NAME', content: this.patientInfo.profile.preferred_name, showCopyButton: true },
        { label: 'COMMON.EMAIL', content: this.patientInfo.profile.email, showCopyButton: true },
        { label: 'copy_1289', content: phoneNumber, showCallButton: true, showCopyButton: true, warningMessage: phoneWarningMessage },
      ]
    },
  },
  methods: {
    changeAttentionLevel(level) {
      const patientId = this.patientInfo.profile.user_id
      const body = { attention_level: level, origin: 'clinical_portal' }

      this.$http('patient/profile/setAttentionLevel', patientId, { body })
    },
    handleCloseFromPropagation($event) {
      if (this.$refs['patient-sidebar-modal-backdrop'] === $event.target) {
        this.closeModal()
      }
    },
    closeModal() {
      this.toggleMemberSidebar()
    },
    openUi({ context }) {
      const patientData = this.getFormatPatientData()

      switch (context) {
        case 'notes':
          this.toggleNotes(patientData)
          break
        case 'sword-desk':
          triggers.openSwordDesk(
            {
              memberEmail: this.patientInfo.profile.email,
              memberAccountUuid: this.patientInfo.profile.member_account_id,
              memberProgramUuid: this.memberUuid,
            })

          return
        default:
          console.warn('[patient-sidebar] Requested unsupported option')
      }
      this.closeModal()
    },
    async copyToClipboard({ context, content }) {
      const contextsAlreadyCopied = ['patient-id']
      const shouldCopy = !contextsAlreadyCopied.includes(context)

      let contentToCopy = content

      if (!contentToCopy) {
        // since not content, get context to get the right content ouside component scope to copy
        switch (context) {
          case 'reassessment-link':
            contentToCopy = await this.requestAppointmentLink('reassessment')
            break
          case 'ob-call-link':
            contentToCopy = await this.requestAppointmentLink('reschedule-onboarding')
            break
          case 'check-in-link':
            contentToCopy = await this.requestAppointmentLink('check_in_call')
            break
          default:
            console.warn('[patient-sidebar] Requested unsupported appointment type')
        }
      }

      if (contentToCopy) {
        try {
          if (shouldCopy) await this.$copyText(contentToCopy)
          this.$notify.success(this.$t('COPIED.CLIPBOARD'))
        } catch (error) {
          this.$notify.error(this.$t('copy_89'))
        }

        return
      }
      console.warn('[patient-sidebar] Coulnd\'t get any content to copy')
    },
    async sendToUser({ context }) {
      try {
        switch (context) {
          case 'reassessment-link':
            await this.requestAppointmentLink('reassessment', true)
            break
          case 'check-in-link':
            await this.requestAppointmentLink('check_in_call', true)
            break
          default:
            console.warn('[patient-sidebar] Requested unsupported appointment type')
            throw Error('Unsupported appointment type')
        }
        const member = `${this.patientInfo.profile.firstname} ${this.patientInfo.profile.lastname}`

        this.$notify.success(this.$t('APPOINTMENT_LINK.LINK_SENT_TO_MEMBER', { member }))

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    getFormatPatientData() {
      return {
        user_id: this.patientInfo.profile.user_id,
        firstname: this.patientInfo.profile.firstname,
        lastname: this.patientInfo.profile.lastname,
        vip: !!this.patientInfo.profile.is_vip_account,
        highRisk: !!this.patientInfo.profile.patient_is_high_risk,
        name: `${this.patientInfo.profile.firstname} ${this.patientInfo.profile.lastname}`,
        gender: this.patientInfo.profile.gender,
        picture: this.patientInfo.profile.picture,
      }
    },
    async requestAppointmentLink(appointmentType, sendBySms = false) {
      try {
        const { appointmentUrl } = await this.generateAppointmentLink(appointmentType, sendBySms)

        return Promise.resolve(appointmentUrl)
      } catch (e) {

        return Promise.reject(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$patient-summary-bar-top: 3.1rem;
$patient-summary-bar-width: 28rem;

.modal-backdrop.patient-summary-bar-backdrop {
  background-color: transparent;
  z-index: 30;
}

.patient-summary-bar-enter,
.patient-summary-bar-leave-to {
  opacity: 0;
  transform: translateX(110%);
}

.patient-summary-bar-enter-to,
.patient-summary-bar-leave {
  opacity: 1;
  transform: translateX(0);
}

.patient-summary-bar-enter-active {
  transition: all 0.3s ease-out;
}

.patient-summary-bar-leave-active {
  transition: all 0.1s ease-in;
}

.patient-summary-bar {
  position: fixed;
  top: $patient-summary-bar-top;
  right: 0;
  height: calc(100vh - $patient-summary-bar-top);
  width: $patient-summary-bar-width;
  box-shadow: 0 0.25rem 0.75rem 0 rgb(31, 34, 44, 0.25);

  .patient-summary-overview {
    min-height: 100%;
  }

  ::v-deep {
    ul.levels {
      margin-bottom: 0.6rem;
    }
  }

  &--loading {
    background-color: #ffffff;
    box-shadow: 0 0.25rem 0.75rem 0 rgb(31 34 44 / 25%);
    width: $patient-summary-bar-width;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: $max-smartphone-size) {
    top: 0;
    left: 0;
    height: 100%;
  }
}
</style>
