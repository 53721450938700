<template>
  <div>
    <slot></slot>

    <theme-selector
      v-if="showThemeSelector"
      :current-theme="currentThemeName"
      @change-theme="selectPortalTheme"
    />
  </div>
</template>

<script lang="ts">
import { ref, watchEffect, onMounted, computed } from 'vue'
import ThemeSelector from '@ui-kit/dev-utils/theme-selector/theme-selector.vue'
// @ts-expect-error - cannot find declaration file - web-ui-kit is not typed
import { selectTheme } from '@ui-kit/themes'
// @ts-expect-error - tsconfig moduleResolution is set to node, so it can't resolve the theme modules
// Must be set with nodenext or bundler
// TODO: check if it's possible to change the moduleResolution to bundler
import * as themes from '@swordhealth/ui/themes'
import { useStorage } from '@vueuse/core'
import { APP_THEME } from '@/scripts/app-configs/constants'

interface Theme {
  name: string;
  styles: string;
  icons?: Record<string, string>;
  colors?: Record<string, Record<string, string> | string>;
  ui?: Record<string, Record<string, string> | string>;
}

export default {
  name: 'ThemeProvider',
  components: { ThemeSelector },
  setup() {
    const usePreferenceTheme = useStorage(APP_THEME.key, 'sword')
    const availableThemes: Theme[] = Object.values(themes)

    const defaultTheme: Theme = themes.SwordTheme
    const defaultUserTheme = availableThemes.find((t) => t.name === usePreferenceTheme.value)

    const currentTheme = ref<Theme>(defaultUserTheme || defaultTheme)
    const currentThemeName = computed(() => currentTheme.value?.name)

    const showThemeSelector = process.env.NODE_ENV === 'development' && false

    function setThemeStyles(theme: Theme) {
      if (typeof window !== 'undefined') {
        const el = document.querySelector('style[data-theme-style]')

        if (el) {
          el.setAttribute('data-theme-style', theme.name)
          el.textContent = theme.styles
        } else {
          const themeStyle: HTMLStyleElement = document.createElement('style')

          themeStyle.type = 'text/css'
          themeStyle.setAttribute('data-theme-style', theme.name || '')
          themeStyle.textContent = theme.styles
          document.head.appendChild(themeStyle)
        }
      }

    }

    const selectPortalTheme = (themeName: string) => {
      const selectedTheme = availableThemes.find((t) => t.name === themeName) || defaultTheme

      currentTheme.value = selectedTheme
      selectTheme(selectedTheme.name)
      setThemeStyles(selectedTheme)
    }

    watchEffect(() => {
      setThemeStyles(currentTheme.value)
    })

    onMounted(() => {
      setThemeStyles(currentTheme.value) // Apply initial theme on mount
    })

    return {
      currentTheme,
      currentThemeName,
      showThemeSelector,
      selectPortalTheme,
      availableThemes,
    }
  },
}
</script>
