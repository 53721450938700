import Vuex from 'vuex'
import Vue from 'vue'

/**
 * Plugins
 */

import userStoragePlugin from '@/store/plugins/userStorage'
import systemStoragePlugin from '@/store/plugins/systemStorage'
import prescriptionJustificationPlugin from '@/store/plugins/prescriptionJustification'

/**
 * Modules
 */
import system from './modules/systemStore'
import general from './modules/generalStore'
import user from './modules/userStore'
import prescription from './modules/prescriptionStore'
import patient from './modules/patientStore'
import configs from './modules/remoteConfigsStore'
import clinical from './modules/clinicalStore'
import recommendations from './modules/recommendationsStore'
import results from './modules/resultsStore'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    system,
    general,
    user,
    prescription,
    patient,
    clinical,
    recommendations,
    results,
    configs,
  },
  plugins: [
    userStoragePlugin.plugin, systemStoragePlugin.plugin, prescriptionJustificationPlugin,
  ],
})
