import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    therapist_chat: {
      default: false,
      value: false,
      apiKey: 'therapist_chat_enabled',
    },
    clinical_forms: {
      default: false,
      value: false,
      apiKey: 'show_clinical_forms',
    },
    date_format: {
      default: 'DD/MM/YYYY',
      value: false,
      apiKey: 'date_format',
    },
    pt_regulation_status: {
      default: false,
      value: false,
      apiKey: 'check_pt_regulation_status',
    },
    state_referral_rules: {
      default: [],
      value: [],
      apiKey: 'clinical_referral_rules',
    },
    with_weight_enabled: {
      default: false,
      value: false,
      apiKey: 'with_weight_enabled',
    },
  },
  getters: {
    getConfig: (state) => (key) => state[key],
    isConfigEnabled: (state) => (key) => state[key] && state[key].value,
  },
  mutations: {
    setConfig(state, { key, value }) {
      const [toggleName] = Object.entries(state).find(([, tc]) => tc.apiKey === key)

      if (!toggleName) {
        return
      }
      state[toggleName].value = value
    },
  },
  actions: {
    async fetch({ state, commit, dispatch }, featureKeys) {
      let togglesToGet = []

      if (typeof featureKeys === 'string') {
        togglesToGet = featureKeys === 'all' ? Object.values(state).map((tc) => tc.apiKey) : togglesToGet.push(featureKeys)
      } else {
        togglesToGet = featureKeys
      }
      try {
        const toggleRemoteConfigs = await dispatch('fetchSystemConfigs', togglesToGet)

        togglesToGet.forEach((toggleKey) => {
          if (!(toggleKey in toggleRemoteConfigs)) {
            return
          }
          const { value } = toggleRemoteConfigs[toggleKey]

          commit('setConfig', { key: toggleKey, value })
        })
      } catch (e) {
        console.error('[remote-system-configs] Error fetching remote system configs', e)
      }
    },
    async fetchSystemConfigs(_, keys) {
      try {
        const response = await Vue.$http('config/getSystemConfigs', { context: 'system', keys })

        return response.data
      } catch (error) {
        console.error(`Error getting system configs: ${keys}`)

        return null
      }
    },
    async fetchUserConfig({ rootGetters }, { group, keys }) {
      try {
        const userId = rootGetters['user/getUserId']
        const response = await Vue.$http('config/getConfigs', { context: 'user', userId, keys, group })

        return response.data
      } catch (error) {
        console.error(`Error getting user config for ${keys}`)

        return null
      }
    },
    async setUserConfig({ rootGetters }, { group, key, value }) {
      const payload = { group, key, value }
      const userId = rootGetters['user/getUserId']

      try {
        await Vue.$http('config/setConfig', { context: 'user', userId }, { body: payload })
      } catch (error) {
        console.error(`Error setting user config for ${key}`)
      }
    },
  },
}
