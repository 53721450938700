<template>
  <feed
    :total-items="newNotificationsNumber"
    :is-modal-open="isModalOpen"
    :show-load-more="totalNotifications > limit"
    :is-fetching="isFetching"
    :show-items="!!notifications.length"
    title="NOTIFICATIONS.NOTIFICATIONS"
    no-items-text="NOTIFICATIONS.NOTIFICATIONS.NO"
    @load-more="loadMore"
    @close-modal="closeModal"
  >
    <template v-if="newNotificationsNumber" #header-right>
      <button class="button-link -t_medium" @click="markAllAsRead">
        {{ $t('NOTIFICATIONS.ACTIONS.MARK_ALL_READ') }}
      </button>
    </template>
    <li v-for="notification in notifications" :key="`notification-${notification.uuid}`">
      <notification-item
        v-if="notification.type === 'new_chat_message' || notification.type === 'new_voicemail'"
        :notification="notification"
        @close="closeModal"
      />
      <notification-item-program-summary v-else :notification="notification" @close="closeModal"/>
    </li>
  </feed>
</template>

<script>
// Components
import Feed from '@/components/feed.vue'
import NotificationItem from '@/components/notifications/NotificationItem.vue'
import NotificationItemProgramSummary from '@/components/notifications/NotificationItemProgramSummary.vue'

// Utils
import { triggers } from '@/scripts/global-modals-commands'
import { computed } from 'vue'

// Configs
const DEFAULT_OFFSET = 20

const setDefaultState = () => ({
  notifications: [],
  totalNotifications: 0,
  currentPage: 0,
})

export default {
  name: 'NotificationsFeed',
  components: {
    NotificationItem,
    NotificationItemProgramSummary,
    Feed,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isModalOpen = computed(() => props.modalOpen)

    return { isModalOpen }
  },
  data() {
    return {
      ...setDefaultState(),
      isFetching: false,
      limit: DEFAULT_OFFSET,
    }
  },
  computed: {
    newNotificationsNumber() {
      return this.$store.getters['general/newNotificationsNumber']
    },
  },
  watch: {
    async isModalOpen(opened) {
      if (opened) {
        this.resetState()
        await this.fetchNotifications()

        return
      }
      this.newNotificationsNumber && this.markNotificationsAsRead()
    },
  },
  methods: {
    async fetchNotifications(configs = {}) {
      if (this.isFetching) {
        return
      }
      const limit = configs.limit || this.limit
      const offset = configs.offset || this.currentPage * limit

      try {
        this.isFetching = true
        const { data } = await this.$http('notifications/fetchNotifications', { offset, limit })

        this.notifications = this.notifications.concat(data.items)
        this.totalNotifications = data.total
      } catch (error) {
        console.error('[pp-notifications] Error fetching notifications list.', error)
      } finally {
        this.isFetching = false
      }
    },
    loadMore() {
      if (this.notifications.length >= this.totalNotifications) {
        return
      }
      this.currentPage++
      this.fetchNotifications()
    },
    async markAllAsRead() {
      try {
        await this.$http('notifications/markAllAsRead')
        this.$store.commit('general/setNewNotificationsNumber', 0)
        this.notifications = this.notifications.map((notification) => ({ ...notification, is_read: true }))
      } catch (error) {
        console.error('[pp-notifications] Error while trying to mark all notifications as read.', error)
      }
    },
    async markNotificationsAsRead() {
      const unreadNotificationsUuids = this.notifications.filter(({ is_read }) => !is_read).map(({ uuid }) => uuid)

      try {
        await this.$http('notifications/toggleReadStatus', null, { body: { items: unreadNotificationsUuids, status: true } })
        const updatedCounter = this.newNotificationsNumber - unreadNotificationsUuids.length

        this.$store.commit('general/setNewNotificationsNumber', updatedCounter)
      } catch (error) {
        console.error(`[pp-notifications] Error while trying to mark notifications ${unreadNotificationsUuids.join(',')} as read.`, error)
      }
    },
    resetState() {
      const defaultState = setDefaultState()

      Object.entries(defaultState).forEach(([prop, value]) => {
        this[prop] = value
      })
    },
    closeModal() {
      triggers.toggleNotificationsFeed()
    },
  },
}
</script>
