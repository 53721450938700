import Vue from 'vue'

// Modules
import authentication from './user/authenticationStore'
import preferences from './user/preferencesStore'

const defaultUserState = () => {
  return {
    user: {}, // Rename this when possible (ex. Profile)
    flags: {
      isAIPt: false,
      isLeadPt: false,
      isTreatmentPt: false,
      isIntakePt: false,
      isClinicalAssistantManager: false,
      hasProviderEnabled: false,
    },
    institutions: {
      list: [],
      clientConfigsByInstitutionId: {},
    },
  }
}

export default {
  namespaced: true,
  modules: {
    authentication,
    preferences,
  },
  state: {
    ...defaultUserState(),
  },
  getters: {
    getUserId: (state) => state.user && state.user.id,
    userExist: (state) => !!Object.keys(state.user).length,
    getInstitutionsList: (state) => state.institutions.list,
    getUserRole: (state) => state.user && state.user.role,
    getUserUsername: (state) => state.user && state.user.username,
    getUserFullName: (state) => `${state.user.firstname} ${state.user.lastname}`,
    enabledCommunication: (state) => {
      return {
        chat: !!state.user.sendbird?.sendbird_id,
        sms: !!state.user.externalPhoneNumber,
      }
    },
    getBasicProfile: (state, getters) => {
      if (!getters.userExist) return {}

      return {
        id: state.user.id,
        firstname: state.user.firstname,
        lastname: state.user.lastname,
        picture: state.user.picture,
        gender: state.user.gender,
        username: state.user.username,
        email: state.user.email,
        role: state.user.role,
        direct_message_address: state.user.direct_message_address,
        telephone: state.user.telephone,
        phone: state.user.phone,
        measurement_system: state.user.measurement_system,
      }
    },
    flags: (state) => state.flags,
    currentInstitutionId: (state, getters, rootState, rootGetters) => {
      return rootGetters['patient/getMemberBasics']?.institutionId || null
    },
    getClientsConfigs: (state) => state.institutions.clientConfigsByInstitutionId,
  },
  mutations: {
    setInstitutions(state, institutionsList) {
      state.institutions.list = institutionsList
    },
    setFlags(state, flags) {
      Object.entries(flags).forEach(([flag, value]) => {
        state.flags[flag] = value
      })
    },
    setInstitutionClientConfigs(state, { institutionId, configs }) {
      Vue.set(state.institutions.clientConfigsByInstitutionId, institutionId, configs)
    },
    setProfile(state, profile) {
      state.user = profile
    },
    updateProfile(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.user[key] = payload[key]
      })
    },
    resetProfile(state) {
      Object.assign(state, defaultUserState())
    },
    resetInstitutions(state) {
      const rawInstitutionsState = defaultUserState().institutions

      Object.entries(rawInstitutionsState).forEach(([prop, value]) => {
        Vue.set(state, prop, value)
      })
    },
  },
}
